@import '../../styles/variables.scss';
@import '../../styles/grid';

.component--open-req {
  margin-bottom: 8px;

  .component--open-req__copies__title {
    font-size: 18px;

    &:hover {
      color: $color__action;
      text-decoration: underline;
    }

    @media screen and (max-width: $viewport-small) {
      font-size: 16px;
    }
  }

  .component--open-req__copies__position {
    margin-bottom: 8px;
    font-size: 16px;
  }

  .component--open-req__copies__description {
    margin-bottom: 8px;
  }
}