@import './styles';

body {
  background-color: $color__white--tinted;

  .p5Canvas[id^="defaultCanvas"] {
    display: none;
  }

  &.no-scroll {
    overflow: hidden;
  }
}