@import '../../styles/variables.scss';
@import '../../styles/grid';

.component--no-data-found {
  padding: 16px;
  text-align: center;

  .component--no-data-found__label {
    font-style: italic;
    color: $color__gray--light;
  }
}