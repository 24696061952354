@import '../../styles/variables.scss';

.component--loader {
  display: inline-block;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 9999;
  background-color: rgba($color--white--tinted, 0.8);
  opacity: 1;
  
  &.hidden {
    opacity: 0;
    transition: all 0.3s ease-out;
    animation-delay: 0.2s;
    pointer-events: none;
  }

  .component--loader__element {
    box-sizing: border-box;
    display: block;
    position: absolute;
    left: calc(50% - 64px);
    top: calc(50% - 64px);
    width: 128px;
    height: 128px;
    margin: 8px;
    border: 4px solid $color__highlight;
    border-radius: 50%;
    animation: lds-ring 1.6s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $color__highlight transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg) ease-in-out;
    }
    100% {
      transform: rotate(360deg);
    }
  }
}