@import '../../styles/variables.scss';
@import '../../styles/grid';

.component--profile {
  text-align: center;
  margin-bottom: 32px;

  .component--profile__image {
    width: 120px;
    height: 120px;
    margin: 0 auto 16px;
    border-radius: 50%;
    background-color: $color__gray--extra-light;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .component--profile__copies__name {
    font-size: 20px;
  }

  .component--profile__copies__position {
    margin-bottom: 8px;
    font-size: 16px;
  }

  .component--profile__copies__description {
    margin-bottom: 8px;
  }
}