@import '../../styles/variables.scss';
@import '../../styles/grid.scss';

.page--our-lab {
  .page--our-lab__hero-tagline {
    text-align: center;
    position: relative;
    left: 0;
    width: calc(100vw - 40px);
    padding: 192px 20px;
    background-image: url('../../assets/images/our-lab__group.jpg');
    background-repeat: no-repeat;
    background-position: 50% 30%;
    background-size: cover;

    .page--our-lab__hero-tagline__backdrop {
      position: absolute;
      content: '';
      z-index: 1;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: $color__gray--dark;
      opacity: 0.5;
    }
    .page--our-lab__hero-tagline__copy {
      position: relative;
      z-index: 2;
    }
  }

  .page--our-lab__container {
    @include container;
    position: relative;
    margin-top: 64px;

    .page--our-lab__alumni {
      text-align: center;
      margin-bottom: 12px;
    }

    .page--our-lab__profile-column__desktop {
      display: block;

      @media screen and (max-width: $viewport-medium) {
        display: none;
      }
    }

    .page--our-lab__profile-column__tablet {
      display: none;

      @media screen and (min-width: $viewport-xsmall) and (max-width: $viewport-medium) {
        display: block;
      }
    }

    .page--our-lab__profile-column__mobile {
      display: none;

      @media screen and (max-width: $viewport-xsmall) {
        display: block;
      }
    }

    .page--our-lab__members--middle-column {
      margin-top: 64px;
    }

    .page--our-lab__members--last-column {
      margin-top: 32px;
    }

    .page--our-lab__join-us__description {
      margin-bottom: 16px;
    }

    .page--our-lab__join-us__open-req {
      margin-top: 16px;

      .page--our-lab__join-us__open-req__no-open-req {
        color: $color__gray--light;
        font-style: italic;
      }
    }

    .page--our-lab__join-us__image-container {
      width: 100%;
      position: relative;

      .page--our-lab__join-us__image {
        width: 100%;
        padding-bottom: 56.25%;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('../../assets/images/our-lab__join-us.png');
        position: relative;
      }

      // .page--our-lab__join-us__oval-1 {
      //   width: 120px;
      //   height: 120px;
      //   background-color: $color__highlight;
      //   border-radius: 50%;
      //   right: 0;
      //   bottom: 0;
      //   position: absolute;
      // }
    }

    .page--our-lab__map-iframe {
      width: 100%;
      height: 400px;
    }

    .page--our-lab__contact__description {
      margin-bottom: 16px;
    }

    .page--our-lab__contact-copy {
      font-size: 18px;

      @media screen and (max-width: $viewport-medium) {
        font-size: 16px;
      }
    }

    .page--our-lab__contact__phone {
      margin-bottom: 16px;
      display: flex;
      gap: 12px;

      .page--our-lab__contact__phone__icon {
        width: 20px;
        height: 20px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('../../assets/icons/phone.png');
      }
    }

    .page--our-lab__contact__email {
      margin-bottom: 16px;
      display: flex;
      gap: 12px;

      .page--our-lab__contact__email__icon {
        width: 20px;
        height: 20px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('../../assets/icons/email.png');
      }
    }

    .page--our-lab__contact__direction {
      margin-bottom: 16px;
    }

    .page--our-lab__contact__address {
      display: flex;
      gap: 12px;

      .page--our-lab__contact__address__icon {
        width: 20px;
        height: 20px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('../../assets/icons/location.png');
      }
    }
  }
}