@import '../../styles/variables.scss';

.component--link-stack {
  .component--link-stack__title {
    text-transform: uppercase;
    color: $color__white;
    font-size: 10px;
    font-weight: bold;
  }

  .component--link-stack__links__ul {
    padding-inline-start: 0;
    margin: 8px 0;
  }

  .component--link-stack__links__li {
    list-style: none;

    a {
      text-transform: none;
      color: $color__gray--light;
      border-bottom: 1px solid transparent;
      padding-bottom: 2px;
      transition: all 0.2s ease-in-out;

      &:hover {
        padding-bottom: 0;
        border-color: $color__gray--light;
      }
    }
  }
}