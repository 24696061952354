@import '../../styles/variables.scss';
@import '../../styles/grid';

.component--news {
  .component--news__title {
    margin-bottom: 4px;
    font-size: 16px;

    @media (max-width: $viewport-xsmall) {
      font-size: 14px;
    }
  }

  .component--news__description {
    @media (max-width: $viewport-xsmall) {
      font-size: 12px;
    }
  }

  .component--news__link {
    margin-top: 8px;
  }

  .component--news__highlight {
    position: relative;
    width: 100%;
    
    .component--news__highlight__image {
      background-color: red;
      width: 100%;
      padding-bottom: 56.25%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('../../assets/images/home__humans.png');
    }

    .component--news__highlight__copies {
      position: absolute;
      width: 80%;
      bottom: 0;
      left: calc(10% - 16px);

      background-color: rgba(255, 255, 255, 0.9);
      padding: 16px;
      
    }
  }
}