@import '../../styles/variables.scss';
@import '../../styles/grid';

.component--section-header {
  display: flex;
  justify-content: center;
}

.component--section-header--subheader {
  justify-content: flex-start;
  font-size: 18px;
}