@import '../../styles/variables.scss';
@import '../../styles/grid';

.component--divider {
  display: flex;
  justify-content: center;

  .component--divider__bar {
    background-color: $color__gray--extra-light;
    width: 200px;
    height: 8px;
    margin: 72px 0 48px;
  }

  @media screen and (max-width: $viewport-medium) {
    .component--divider__bar {
      margin: 48px 0 32px;
      height: 4px;
      width: 120px;
    }
  }

  @media screen and (max-width: $viewport-small) {
    .component--divider__bar {
      margin: 32px 0 24px;
      width: 80px;
    }
  }
}