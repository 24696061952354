@import '../../styles/variables.scss';
@import '../../styles/grid';

.component--publication-highlight {
  display: flex;
  width: 100%;
  border: 1px solid $color__gray--extra-light;
  border-radius: 4px;

  @media screen and (max-width: $viewport-xsmall) {
    flex-wrap: wrap;
  }

  .component--publication-highlight__image {
    width: 50%;
    background-color: $color__gray--extra-light;
    background-size: cover;
    background-repeat: no-repeat;

    @media screen and (max-width: $viewport-xsmall) {
      width: 100%;
      height: 200px;
    }
  }

  .component--publication-highlight__copies {
    width: 50%;
    padding: 24px;
    
    @media screen and (max-width: $viewport-xsmall) {
      width: 100%;
    }

    .component--publication-highlight__copies__title {
      margin-bottom: 8px;
    }

    .component--publication-highlight__link {
      margin-top: 8px;
    }
  }
}