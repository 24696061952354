@import '../../styles/variables.scss';
@import '../../styles/grid';

.component--news-carousel {
  .component--news {
    margin-right: 0;
  }

  .swiper {
    padding-bottom: 32px;

    .swiper-button-prev,
    .swiper-button-next {
      background-color: $color--white;
      width: 48px;
      height: 48px;
      border-radius: 50%;
  
      &::after {
        position: relative;
        font-size: 20px;
        font-weight: bold;
        color: $color__gray--light;
      }
    }
  
    .swiper-button-prev {
      &::after {
        left: -1px;
      }
    }
  
    .swiper-button-next {
      &::after {
        left: 1px;
      }
    }

    .swiper-pagination {
      bottom: 0;
    }
  
    .swiper-pagination-bullet {
      background-color: $color__gray--light;
  
      &.swiper-pagination-bullet-active {
        background-color: $color__highlight;
      }
    }
  }
}