@import '../../styles/grid';

.component--navigation__container {
  @include container;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 32px;

  .component--navigation__titles__team {
    font-size: 24px;
    line-height: 32px;
  }

  .component--navigation__titles__affiliation {
    font-size: 18px;
    font-weight: normal;
    color: $color__gray--medium;
  }

  .component--navigation__items {
    &.desktop {
      display: block;
    }
    &.mobile {
      display: none;
    }
  }

  .component--navigation__item {
    color: $color__gray--dark;
    margin: 0 12px;
    padding: 16px 0;
    position: relative;
    transition: all 0.1s ease-out;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: calc(50% - 2px);
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: transparent;
      transition: all 0.25s ease-out;
    }
    
    &:hover {
      &::after {
        background-color: $color__gray--extra-light;
      }
    }

    &.active {
      font-weight: bold;
      
      &::after {
        background-color: $color__highlight;
      }
    }
  }
}

@media only screen and (max-width: $viewport-medium) {
  .component--navigation__titles {
    .component--navigation__titles__team {
      font-size: 18px;
      line-height: 24px;
    }
  
    .component--navigation__titles__affiliation {
      font-size: 14px;
    }
  }
}

@media only screen and (max-width: $viewport-small) {
  .component--navigation__container {
    .component--navigation__items {
      &.desktop {
        display: none;
      }
      &.mobile {
        display: block;
        position: relative;

        .component--navigation__three-bars {
          padding: 12px 13px;
          border-radius: 50%;
          border: 1px solid $color__gray--extra-light;
          cursor: pointer;

          .bar {
            width: 20px;
            height: 2px;
            background-color: $color__gray--light;
            margin: 4px 0;
            transition: all 0.15s ease-out;
          }

          &.open {
            .bar {
              &:nth-child(1) {
                transform: translateY(6px) rotate(45deg);
              }
              &:nth-child(2) {
                opacity: 0;
              }
              &:nth-child(3) {
                transform: translateY(-6px) rotate(-45deg);
              }
            }
          }

          &:hover {
            .bar {
              background-color: $color__gray--medium;
            }
          }
        }

        .component--navigation__mobile-menu {
          position: fixed;
          z-index: 100;
          width: calc(100vw - 40px);
          height: 100vh;
          background-color: $color__white--tinted;
          opacity: 0;
          transition: opacity 0.15s ease-out;
          pointer-events: none;
          left: 0;
          top: 132px;

          padding: 32px 20px;

          &.open {
            opacity: 0.9;
            pointer-events: all;
          }

          .component--navigation__item {
            display: block;
            border-top: 1px solid $color__gray--extra-light;
            margin: 0;
            padding: 20px 16px;

            &::after {
              left: 0;
              top: calc(50% - 2px);
            }
          }
        }
      }
    }
  }
}