@import '../../styles/variables.scss';
@import '../../styles/grid.scss';

.page--research {
  .page--research__principles__container {
    background-color: $color__gray--dark;
    padding-bottom: 48px;
    background-image: url('../../assets/images/research__city.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    z-index: 1;

    .page--research__principles__container__backdrop {
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, #36E07007, #36E07014 50%, #36E07021 100%);
    }

    .page--research__principles {
      @include container;
      position: relative;
      z-index: 3;
  
      .page--research__principles__hero-tagline {
        text-align: center;
        padding: 192px 0;

        @media screen and (max-width: $viewport-medium) {
          padding: 128px 0;
        }

        @media screen and (max-width: $viewport-small) {
          padding: 72px 0;
        }
      } 
  
      .page--research__principles__overview-copy {
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: $viewport-small) {
          display: none;
        }
      }

      .page--research__principles__overview-copy--mobile {
        display: none;

        @media screen and (max-width: $viewport-small) {
          display: block;
          font-size: 16px;
          margin-top: 8px;
          margin-bottom: 24px;
          text-align: center;
        }
      }
    }
  }

  .page--research__publications {
    @include container;
    margin-top: 72px;

    .page--research__publications__header {
      margin-bottom: 24px;
    }
  
    .page--research__publications__search {
      margin-top: 52px;
      position: relative;
  
      .page--research__publications__search__icon {
        content: url('../../assets/icons/search.png');
        position: absolute;
        bottom: 8px;
        left: 0;
        width: 16px;
        height: 16px;
        transition: all 0.15s ease-out;
      }
  
      .page--research__publications__search__input-field {
        width: calc(100% - 24px);
        padding: 8px 0 8px 24px;
        border: none;
        border-bottom: 1px solid $color__gray--extra-light;
        border-radius: 0;
        background-color: transparent;
        outline: none;
        transition: all 0.15s ease-out;
        position: relative;
  
        &:focus,
        &:hover {
          border-bottom: 1px solid $color__gray--light;
        }
    
        &:active {
          border-bottom: 1px solid $color__gray--medium;
        }
      }
    }
  
    .page--research__publications__list {
      // margin-top: 24px;
  
      .page--research__publications__list__group {
        margin-top: 24px;
        
        .page--research__publications__list__group__year {
          margin-bottom: 4px;
        }
  
        .component--publication {
          margin-bottom: 12px;
        }
      }
    }
  }
}