@import '../../styles/variables.scss';
@import '../../styles/grid';

.component--image-container {
  width: 100%;
  padding-bottom: 100%;
  background-color: $color__gray--medium;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &.ratio--16-9 {
    padding-bottom: 56.25%;
  }
}