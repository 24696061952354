@import '../../styles/variables.scss';
@import '../../styles/grid';

.component--footer {
  background-color: $color__gray--dark;

  .component--footer__container {
    @include container;
    padding: 48px 20px 32px;

    .component--footer__titles__team {
      color: $color__highlight;
      font-size: 22px;
      margin-bottom: 8px;
    }

    .component--footer__titles__affiliation {
      font-size: 16px;
    }

    @media only screen and (max-width: $viewport-small) {
      .component--footer__titles__team {
        color: $color__highlight;
        font-size: 16px;
      }
  
      .component--footer__titles__affiliation {
        font-size: 12px;
      }
    }
  }
}