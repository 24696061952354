@import '../../styles/variables.scss';
@import '../../styles/grid.scss';

.page--home {
  .page--home__continer {
    @include container;
    margin-top: 72px;
    margin-bottom: 72px;

    @media screen and (max-width: $viewport-medium) {
      margin-top: 32px;
    }

    @media screen and (max-width: $viewport-small) {
      margin-top: 20px;
    }

    .page--home__tagline {
      text-align: right;

      .page--home__tagline__copy {
        color: $color__highlight;

        @media screen and (max-width: $viewport-medium) {
          font-size: 40px;
          line-height: 52px;
        }
      }

      margin-bottom: 32px;

      @media screen and (max-width: $viewport-small) {
        text-align: center;
      }
    }

    .page--home__description {
      max-width: 480px;
      margin-left: auto;
      text-align: right;
      
      @media screen and (max-width: $viewport-small) {
        text-align: center;
        max-width: none;
      }
    }

    .page--home__image__background {
      background-color: $color__highlight;
      width: 360px;
      height: 360px;
      margin: 0 auto;
      border-radius: 50%;
      opacity: 0.1;
    }

    .page--home__news__carousel {
      margin-top: 32px;
      margin-bottom: 32px;
    }

    .page--home__research-overview__headline {
      text-align: center;
      margin-bottom: 24px;
    }

    // .page--home__research-overview__description {
    //   margin-bottom: 24px;
    // }

    .page--home__research-overview__images {
      display: flex;
      position: relative;

      @media screen and (min-width: $viewport-small) and (max-width: $viewport-medium) { 
        flex-wrap: wrap;
      }

      .page--home__research-overview__image__1 {
        position: relative;
        width: 53.5%;
        padding-bottom: 75%;
        background-color: $color__gray--extra-light;
        clip-path: polygon(0% 100%, 0% 0%, 82.5% 0%, 100% 100%);
        left: 3.5%;
        background-image: url('../../assets/images/home__celegans.png');
        background-size: cover;
        background-repeat: no-repeat;
      }

      .page--home__research-overview__image__2 {
        position: relative;
        width: 53.5%;
        padding-bottom: 75%;
        background-color: $color__gray--light;
        clip-path: polygon(17.5% 100%, 0% 0%, 100% 0%, 100% 100%);
        left: -3.5%;
        background-image: url('../../assets/images/home__humans.png');
        background-size: cover;
        background-repeat: no-repeat;
      }

      @media screen and (max-width: $viewport-small) {
        margin-bottom: 8px;

        .page--home__research-overview__image__1 {
          padding-bottom: 40%;
        }
  
        .page--home__research-overview__image__2 {
          padding-bottom: 40%;
        }
      }

      @media screen and (min-width: $viewport-small) and (max-width: $viewport-medium) {
        .page--home__research-overview__image__1 {
          position: relative;
          width: 100%;
          padding-bottom: 60%;
          background-color: $color__gray--extra-light;
          clip-path: polygon(0% 100%, 0% 0%, 100% 0%, 100% 82.5%);
          background-image: url('../../assets/images/home__celegans.png');
          background-size: cover;
          left: 0;
          top: 20px;
        }
  
        .page--home__research-overview__image__2 {
          position: relative;
          width: 100%;
          padding-bottom: 60%;
          background-color: $color__gray--light;
          clip-path: polygon(0% 100%, 0% 17.5%, 100% 0%, 100% 100%);
          background-image: url('../../assets/images/home__humans.png');
          background-size: cover;
          left: 0;
          top: -20px;
        }
      }
    }
  }
}