// Colors
$color__white: #ffffff;
$color__white--tinted: #F7F7F7;
$color__gray--dark: #282828;
$color__gray--medium: #575757;
$color__gray--light: #8D8D8D;
$color__gray--extra-light: #D2D2D2;
$color__action: #365CE0;
$color__highlight: #36E070;

// Viewports
$viewport-xsmall: 576px;
$viewport-small: 768px;
$viewport-medium: 1024px;
$viewport-large: 1280px;
$viewport-infinite: 999999px;

// Typography Variables
$font-weight--bold: 900;
$font-weight--regular: 400;
$font-name--serif: 'notoSerifJPBlack', serif;
$font-name--sans: 'Noto Sans', sans-serif;

// Max Width
$composition--max-width: 1320px;
$composition--outer-gutter: 3rem;
$composition--outer-gutter--mobile: 1.5rem;